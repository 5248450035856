$('.salsify-product-suggestions').select2({
  theme: 'bootstrap-5',
  placeholder: 'Search for Salsify Product',
  allowClear: true,
  templateSelection: format_suggestion_item,
  templateResult: format_suggestion_item,
  ajax: {
    url: '/utils/autocomplete',
    dataType: 'json',
    width: 'style',
    delay: 250,
    data: function(params) {
      return {
        q: params.term,
        page: params.page,
        per_page: 10
      };
    },
    processResults: function(data, page) {
      return {
        results: data.matches.map(function(item) {
          return {
            id: item.id,
            text: item.text,
            image_url: item.image_url
          };
        }),
        pagination: {
          more: data.matches.length > 0
        }
      };
    },
    cache: true
  }
});

function format_suggestion_item(item) {
  if (!item.image_url) {
    return $('<span style="margin-left: 60px;">' + item.text + '</span>')
  }
  return $('<img src="' + item.image_url + '" style="width: 50px; margin-right: 10px"/><span>' + item.text + '</span>');
}
