import consumer from './consumer'

const room_id = window.js_data?.admin_user_id;

room_id && consumer.subscriptions.create({ channel: 'AdminUserChannel', room_id: room_id }, {
  connected() {},

  disconnected() {},

  received(data) {
    if (data['action'] === 'variant_csv') {
      if (data['url']) {
        document.getElementById('flash-display').innerHTML = this.link(data['url'], data['key']);
        $('#file-urls select option')[0]?.after(new Option(data['key'], data['url']));
      } else if (data['percentage'] || data['percentage'] === 0) {
        document.getElementById('flash-display').innerHTML = this.progress_bar(parseFloat(data['percentage']));
      }
    }
  },

  link(url, key) {
    return "<div onclick=location.href='" + url + "' style='cursor:pointer; text-decoration:underline' class='alert alert-success alert-link' role='alert'>" + key + "</div>";
  },

  progress_bar(percentage) {
    return "<div class='progress' style='height:50px;'><div class='progress-bar bg-success' role='progressbar' style='height:50px; width: " + percentage + "%;' aria-valuenow='" + percentage + "' aria-valuemin='0' aria-valuemax='100'>" + percentage + "%</div></div>"
  }
});
