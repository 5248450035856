const createUrl = ({origin, pathname, search, hash}) =>
  `${origin}${pathname}${search}${hash}`;
const changeParams = (newParams) => {
  const { origin, pathname, search, hash } = window.location;
  const newSearchParams = new URLSearchParams(search);
  Object.entries(newParams).forEach(([key, value]) => newSearchParams.set(key, value));
  const newSearch = newSearchParams.toString() !== '' ? `?${newSearchParams.toString()}` : '';
  window.location = createUrl({ origin, pathname, search: newSearch, hash });
};

window.createUrl = createUrl;
window.changeParams = changeParams;
