export async function initMap() {
  const mapElement = document.getElementById("map");
  const mapData = JSON.parse(mapElement.dataset.tableData);
  const zonesCoordinates = JSON.parse(mapElement.dataset.zones);
  let statesDraw = {};
  let numbersLabels = [];

  const defaultStyle = {
    strokeColor: "#000000",
    strokeOpacity: 0.8,
    strokeWeight: 1,
    fillColor: "#ffffff",
    fillOpacity: 0.35,
  };

  class FixedLabel extends google.maps.OverlayView {
    constructor(options) {
      super();
      Object.assign(this, options);
      this.div = null;
      this.setMap(this.map);
    }

    createDiv() {
      const div = document.createElement('div');
      div.style.position = 'absolute';
      div.style.fontSize = `${this.fontSize}px`;
      div.style.color = this.color;
      div.style.textAlign = this.align;
      div.style.backgroundColor = 'rgba(0, 0, 0, 0.8)'; // Add black background with 80% opacity
      div.textContent = this.text;
      div.style.zIndex = 1000; // high value for zIndex to make the labels appear on top
      return div;
    }

    onAdd() {
      this.div = this.createDiv();
      this.getPanes().overlayLayer.appendChild(this.div);
    }

    draw() {
      const overlayProjection = this.getProjection();
      const point = overlayProjection.fromLatLngToDivPixel(this.position);
      Object.assign(this.div.style, {
        left: `${point.x}px`,
        top: `${point.y}px`
      });
    }

    onRemove() {
      this.div?.parentNode.removeChild(this.div);
      this.div = null;
    }

    toggleVisibility() {
      if (this.div) {
        this.div.style.display = this.div.style.display === 'none' ? '' : 'none';
      }
    }
  }

  const map = new google.maps.Map(mapElement, {
    zoom: 4.5,
    center: {
      lat: 38.2,
      lng: -98
    },
  });

  google.maps.event.addListenerOnce(map, 'tilesloaded', async () => {
    const stateFeatures = await fetchStates();
    displayNumbers(stateFeatures);
    drawStates(stateFeatures);

    setTimeout(async () => {
      displayZones();
    }, 200);
  });

  function createArrowButton(arrow) {
    const button = document.createElement("button");
    button.innerHTML = arrow;
    button.style.backgroundColor = "#ffffff";
    button.style.border = "1px solid #cccccc";
    button.style.borderRadius = "3px";
    button.style.padding = "8px";
    button.style.cursor = "pointer";
    return button;
  }

  function createUnselectStateButton() {
    const button = document.createElement("button");
    button.innerHTML = 'Unselect current state';
    button.style.backgroundColor = "#ffffff";
    button.style.border = "1px solid #cccccc";
    button.style.borderRadius = "3px";
    button.style.padding = "8px";
    button.style.cursor = "pointer";
    return button;
  }

  function createToggleNumbersButton() {
    const button = document.createElement("button");
    button.innerHTML = 'Toggle counters visibility';
    button.style.backgroundColor = "#ffffff";
    button.style.border = "1px solid #cccccc";
    button.style.borderRadius = "3px";
    button.style.padding = "8px";
    button.style.cursor = "pointer";
    return button;
  }

  const toggleNumbersButton = createToggleNumbersButton();
  const unselectStateButton = createUnselectStateButton();
  const leftArrow = createArrowButton("← Previous zone");
  const rightArrow = createArrowButton("Next zone →");

  map.controls[google.maps.ControlPosition.TOP_RIGHT].push(rightArrow);
  map.controls[google.maps.ControlPosition.TOP_RIGHT].push(leftArrow);
  map.controls[google.maps.ControlPosition.TOP_RIGHT].push(unselectStateButton);
  map.controls[google.maps.ControlPosition.TOP_RIGHT].push(toggleNumbersButton);

  unselectStateButton.addEventListener("click", () => {
    selectedState.overrideStyle(selectedFeature, defaultStyle);

    if (selectedState) {
      selectedState = null;
    } else {
      return false;
    }
    const originalBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(38.2, -98)
    );

    map.fitBounds(originalBounds);
    map.setZoom(4.5);
  })


  leftArrow.addEventListener("click", () => {
    if (selectedState) {
      navigateZones(-1);
    }
  });

  rightArrow.addEventListener("click", () => {
    if (selectedState) {
      navigateZones(1);
    }
  });

  toggleNumbersButton.addEventListener("click", () => {
    numbersLabels.forEach((label) => {
      label.toggleVisibility();
    })
  });

  let currentZoneIndex = 0;
  let previouslySelectedZone = null;

  function navigateZones(direction) {
    console.log('navigate' + direction);
    const zones = statesDraw[selectedFeature.getProperty('NAME')].zones;
    console.log('zones for state: ' + zones.length);
    if (zones.length > 0) {
      currentZoneIndex += direction;
      if (currentZoneIndex < 0) {
        currentZoneIndex = zones.length - 1;
      } else if (currentZoneIndex >= zones.length) {
        currentZoneIndex = 0;
      }

      if (previouslySelectedZone) {
        // Reset the style of the previously selected zone
        previouslySelectedZone.setOptions({
          fillColor: null,
          fillOpacity: 0
        });
      }

      const selectedZone = zones[currentZoneIndex];
      selectedZone.setOptions({
        fillColor: null,
        fillOpacity: null
      });

      previouslySelectedZone = selectedZone;

      zoomAndCenterPolygon(map, selectedZone);
    }
  }

  async function fetchStates() {
    const response = await fetch('/geojson.json');
    return (await response.json()).features;
  }

  let selectedState = null;
  let selectedFeature = null;

  function drawStates(stateFeatures) {
    for (const feature of stateFeatures) {
      const stateMapData = new google.maps.Data();
      stateMapData.addGeoJson(feature);

      stateMapData.setStyle(defaultStyle);

      stateMapData.addListener("click", (event) => {
        stateMapData.overrideStyle(event.feature, {
          fillColor: "#FF0000",
          fillOpacity: 0.1,
        });

        if (selectedState && selectedFeature != event.feature) {
          selectedState.overrideStyle(selectedFeature, defaultStyle);
        }
        if (selectedFeature != event.feature) {
          zoomAndCenterFeature(map, event.feature);
        }
        selectedState = stateMapData;
        selectedFeature = event.feature;

        console.log(statesDraw[event.feature.getProperty('NAME')])
      });

      stateMapData.setMap(map);
      const stateName = String(feature.properties.NAME);
      statesDraw[stateName] = { polygon: dataFeatureToPolygon(stateMapData), zones: [] };
    }
  }

  function zoomAndCenterPolygon(map, polygon) {
    console.log('zoom and center')
    const bounds = new google.maps.LatLngBounds();
    const paths = polygon.getPaths();


    paths.forEach(path => {
      path.forEach(latLng => {
        bounds.extend(latLng);
      });
    });

    map.fitBounds(bounds);
  }


  function zoomAndCenterFeature(map, feature) {
    console.log('zoom and center')
    const bounds = new google.maps.LatLngBounds();
    const geometry = feature.getGeometry();

    function processLatLng(latLng) {
      bounds.extend(latLng);
    }

    if (geometry.getType() === 'Polygon') {
      geometry.forEachLatLng(processLatLng);
    } else if (geometry.getType() === 'MultiPolygon') {
      const polygons = geometry.getArray();
      polygons.forEach(polygon => {
        polygon.forEachLatLng(processLatLng);
      });
    }

    map.fitBounds(bounds);
  }

  function dataFeatureToPolygon(stateMapData) {
    const paths = [];

    stateMapData.forEach((feature) => {
      const geometry = feature.getGeometry();

      if (geometry instanceof google.maps.Data.Polygon) {
        const ring = geometry.getArray();
        const path = ring[0].getArray().map((latLng) => new google.maps.LatLng(latLng.lat(), latLng.lng()));
        paths.push(path);
      } else if (geometry instanceof google.maps.Data.MultiPolygon) {
        const polygons = geometry.getArray();
        polygons.forEach((polygon) => {
          const rings = polygon.getArray();
          rings.forEach((ring) => {
            const path = ring.getArray().map((latLng) => new google.maps.LatLng(latLng.lat(), latLng.lng()));
            paths.push(path);
          });
        });
      }
    });

    return new google.maps.Polygon({ paths: paths });
  }

  function getStateForZone(polygon) {
    for (const stateKey of Object.keys(statesDraw)) {
      if (isPolygonInsidePolygon(polygon, statesDraw[stateKey]['polygon'])) {
        return stateKey;
      }
    }
    return null;
  }

  function displayZones() {
    console.log('zones: ' + zonesCoordinates.length);

    const batchSize = 100;
    let batchStart = 0;

    function processBatch() {
      const batchEnd = Math.min(batchStart + batchSize, zonesCoordinates.length);
      for (let i = batchStart; i < batchEnd; i++) {
        const coords = zonesCoordinates[i];
        const googleMapsCoordinates = coords.map(coordGroup =>
          coordGroup.map(coord => new google.maps.LatLng(coord[1], coord[0]))
        );
        console.log('drawing zone');

        const polygon = new google.maps.Polygon({
          paths: googleMapsCoordinates,
          strokeColor: "#0000FF",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillOpacity: 0,
          map: map,
        });

        const zoneKey = JSON.stringify(coords);
        let stateKey = getStateForZone(polygon);

        if (stateKey !== null) {
          statesDraw[stateKey]['zones'].push(polygon);
        }
      }

      batchStart += batchSize;
      if (batchStart < zonesCoordinates.length) {
        setTimeout(processBatch, 0);
      }
    }

    processBatch();
  }

  function isPolygonInsidePolygon(innerPolygon, outerPolygon) {
    const innerPaths = innerPolygon.getPaths();
    const outerPaths = outerPolygon.getPaths();
    const outerPolygonGM = new google.maps.Polygon({ paths: outerPaths });

    for (let i = 0; i < innerPaths.getLength(); i++) {
      const path = innerPaths.getAt(i);
      for (let j = 0; j < path.getLength(); j++) {
        const point = path.getAt(j);
        if (!google.maps.geometry.poly.containsLocation(point, outerPolygonGM)) {
          return false;
        }
      }
    }
    return true;
  }

  function displayNumbers(stateFeatures) {
    const stateAdjustments = {
      'Texas': {lat: 2, lng: -1},
      'Mississippi': {lat: 3, lng: -3},
      'Maine': {lat: 2, lng: -1},
      'Louisiana': {lat: 1, lng: -4},
      'Vermont': {lat: 0, lng: -1},
      'Massachusetts': {lat: 1, lng: -2},
      'New York': {lat: 2, lng: -4},
      'Virginia': {lat: 0, lng: -3},
      'Wisconsin': {lat: -2, lng: 0},
      'Delaware': {lat: 0, lng: -1},
      'Michigan': {lat: -2, lng: 2},
      'Idaho': {lat: -2, lng: 0},
    };
    for (const feature of stateFeatures) {
      const state = feature.properties.NAME;
      const coordinates = getCoordinates(feature);
      const polygon = new google.maps.Polygon({ paths: coordinates, map, visible: false });
      const bounds = getLatLngBounds(coordinates);
      let centerLatLng = bounds.getCenter();
      const mapFilter = document.getElementById("map_filter");

      if (stateAdjustments[state]) {
        const lat = centerLatLng.lat() + stateAdjustments[state].lat;
        const lng = centerLatLng.lng() + stateAdjustments[state].lng;
        centerLatLng = new google.maps.LatLng(lat, lng);
      }

      const number = Number(mapData[state][mapFilter.dataset.selected || 'within_ship_state_count']);

      const label = new FixedLabel({
        text: `${state}: ${number}`,
        position: centerLatLng,
        map,
        fontSize: 13,
        align: 'center',
        color: 'red'
      });
      numbersLabels.push(label);
    }
  }

  function getCoordinates(feature) {
    const coords = feature.geometry.type === 'Polygon'
      ? feature.geometry.coordinates[0]
      : feature.geometry.coordinates[0][0];
    return coords.map(([lng, lat]) => new google.maps.LatLng(lat, lng));
  }

  function getLatLngBounds(coordinates) {
    const bounds = new google.maps.LatLngBounds();
    coordinates.forEach(coordinate => bounds.extend(coordinate));
    return bounds;
  }
}

window.initMap = initMap;


