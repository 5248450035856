$('.dropdown-toggle').on('click', (e) => {
  e.preventDefault();
  e.stopPropagation();

  const klass = 'd-block';
  const dropdown = $(e.target).next('.dropdown-menu');
  if (!dropdown.hasClass(klass)) {
    $('.nav-primary .dropdown-menu').removeClass(klass);
  }
  dropdown.toggleClass(klass);

  $(document).one('click', function closeMenu (e) {
    if ($(e.target).closest('.dropdown-menu').length === 0) {
      $('.nav-primary .dropdown-menu').removeClass(klass);
    } else {
      $(document).one('click', closeMenu);
    }
  });
});
