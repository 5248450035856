import Rails from '@rails/ujs'
// import * as ActiveStorage from "@rails/activestorage"

import 'bootstrap';
import 'jquery';
import 'select2';

// Common
import 'src/javascript/functions';
import 'src/javascript/selectAll';
import 'src/javascript/select2';
import 'src/javascript/toggle.js';
import 'src/javascript/alerts.js';
import 'src/javascript/cronstrue.js';

import 'src/javascript/navigation';
import 'src/javascript/perPage';

import 'src/javascript/admin/kafka/topics';
import 'src/javascript/admin/audit/removeField.js';
import 'src/javascript/admin/liquid/autocomplete.js';
import 'src/javascript/admin/liquid/SDK';
import 'src/javascript/admin/sfcc/scheduled_backfills';
import 'src/javascript/admin/settings/add';
import 'src/javascript/admin/products/availability_map.js';
import 'src/javascript/admin/materialized_views/form.js';

import 'src/javascript/admin/channels/consumer';
import 'src/javascript/admin/channels/index';
import 'src/javascript/admin/channels/adminUserChannel';

Rails.start();
// ActiveStorage.start()
global.Rails = Rails;
