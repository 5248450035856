import cronstrue from 'cronstrue';

function initializeCronstrueInput(element) {
  let message = '';
  try {
    message = cronstrue.toString(element.value);
  } catch (e) {}
  $(element.parentElement.querySelector('.form-text.cronstrue')).text(message);
}

function initializeCronstrue() {
  $('[data-cronstrue]').each((_, element) => {
    const value = $(element).data('cronstrue');
    value && $(element).text(cronstrue.toString(value));
  });
  $(document).on('keyup', '.form-group.cronstrue input', (e) => {
    initializeCronstrueInput(e.target)
  })
  $('.form-group.cronstrue input').each((_, element) => {
    initializeCronstrueInput(element);
  });
}
initializeCronstrue();
